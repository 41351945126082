import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "span"];
  static values = {
    publicKey: String,
  };

  connect() {
    this.initialiseState();
  }

  async initialiseState() {
    if (!("Notification" in window)) {
      console.warn("Push notifications are not supported in this browser");
      this.buttonTarget.disabled = true;
      return;
    }

    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();

      if (subscription) {
        await this.createPushSubscription(subscription);
        this.subscription = subscription;
        this.setToggleEnabled();
      }
    } catch (error) {
      console.error("Error initializing push notification state:", error);
    } finally {
      this.buttonTarget.disabled = false;
    }
  }

  async toggleSubscription() {
    this.buttonTarget.disabled = true;
    try {
      if (this.subscription) {
        await this.unsubscribe();
      } else {
        await this.subscribe();
      }
    } catch (error) {
      console.error("Error toggling subscription:", error);
    } finally {
      this.buttonTarget.disabled = false;
    }
  }

  async subscribe() {
    if (Notification.permission !== "granted") {
      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        return;
      }
    }

    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: this.urlBase64ToUint8Array(this.publicKeyValue),
    });

    await this.createPushSubscription(subscription);
    this.subscription = subscription;
    this.setToggleEnabled();
  }

  async unsubscribe() {
    if (!this.subscription) return;

    await this.deletePushSubscription(this.subscription);
    await this.subscription.unsubscribe();
    this.subscription = null;
    this.setToggleDisabled();
  }

  async createPushSubscription(subscription) {
    const jsonSubscription = subscription.toJSON();
    const response = await this.fetchWithErrorHandling("/push_subscriptions", {
      method: "POST",
      body: JSON.stringify({
        push_subscription: {
          endpoint: jsonSubscription.endpoint,
          auth: jsonSubscription.keys.auth,
          p256dh: jsonSubscription.keys.p256dh,
        },
      }),
    });
    if (!response.ok) throw new Error("Failed to create push subscription");
  }

  async deletePushSubscription(subscription) {
    const response = await this.fetchWithErrorHandling("/push_subscriptions", {
      method: "DELETE",
      body: JSON.stringify({
        push_subscription: { endpoint: subscription.endpoint },
      }),
    });
    if (!response.ok) throw new Error("Failed to delete push subscription");
  }

  async fetchWithErrorHandling(url, options = {}) {
    const defaultOptions = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
    };
    try {
      return await fetch(url, { ...defaultOptions, ...options });
    } catch (error) {
      console.error(`Error fetching ${url}:`, error);
      throw error;
    }
  }

  urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  setToggleEnabled() {
    this.buttonTarget.classList.remove("bg-gray-200");
    this.buttonTarget.classList.add("bg-indigo-600");
    this.spanTarget.classList.remove("translate-x-0");
    this.spanTarget.classList.add("translate-x-4");
  }

  setToggleDisabled() {
    this.buttonTarget.classList.remove("bg-indigo-600");
    this.buttonTarget.classList.add("bg-gray-200");
    this.spanTarget.classList.remove("translate-x-4");
    this.spanTarget.classList.add("translate-x-0");
  }
}
