import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    env: String,
    nonce: String,
  };

  connect() {
    if (!this.hasConsent()) {
      this.element.classList.remove("hidden");
    }
  }

  allowAll() {
    this.setConsent("all");
    this.element.classList.add("hidden");
    this.updateGoogleConsent();
  }

  allowNecessary() {
    this.setConsent("necessary");
    this.element.classList.add("hidden");
  }

  hasConsent() {
    return localStorage.getItem("cookies_consent");
  }

  setConsent(level) {
    localStorage.setItem("cookies_consent", level);
  }

  updateGoogleConsent() {
    if (this.envValue !== "production") {
      return;
    }

    const configScript = document.createElement("script");
    configScript.nonce = this.nonceValue;
    configScript.textContent = `
      gtag('consent', 'update', {
        'analytics_storage': 'granted'
      });
    `;

    document.head.appendChild(configScript);
  }
}
