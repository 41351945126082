import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

// Connects to data-controller="slim-select"
export default class extends Controller {
  static values = {
    allowClear: { type: Boolean, default: false },
    allowSearch: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    addable: { type: Boolean, default: false },
  };

  connect() {
    const options = { openPosition: "down" };
    const events = {};

    options.showSearch = this.allowSearchValue;
    options.searchText = "Nie znaleziono";
    options.searchPlaceholder = "Szukaj";
    options.allowDeselect = this.allowClearValue;

    if (this.placeholderValue !== "") {
      options.placeholderText = this.placeholderValue;
      this.element.options[0].dataset.placeholder = "true";
    }

    if (this.addableValue) {
      options.addableText = "Wciśnij 'Enter', aby dodać";
      events.addable = function (value) {
        return value;
      };
    }

    this.slimselect = new SlimSelect({
      select: this.element,
      settings: options,
      events: events,
    });
  }

  disconnect() {
    this.slimselect.destroy();
  }
}
